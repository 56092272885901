import React, { useState } from 'react';
import banner from './assets/images/banner.jpg';
import { BannerContainer, FormContainer, Form, InputField, Checkbox, ScheduleButton, FooterLink, Alert } from './styles';
import Logo from './assets/images/logo.svg';
import { useFormik } from 'formik'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from 'date-fns';

const validate = values => {
    const errors = {};
    if (!values.firstName) {
        errors.firstName = 'Required';
    } else if (values.firstName.length > 15) {
        errors.firstName = 'Must be 15 characters or less';
    }

    if (!values.lastName) {
        errors.lastName = 'Required';
    } else if (values.lastName.length > 15) {
        errors.lastName = 'Must be 15 characters or less';
    }

    if (!values.email) {
        errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Please enter valid email address';
    }

    if (!values.phone) {
        errors.phone = 'Required';
    } else if (!/^[+]?[0-9]{0,1}[-\s.]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(values.phone)) {
        errors.phone = 'Please enter valid phone number';
    }

    if (values.time === 'none') errors.time = 'Required';

    if (!values.inquiry) errors.inquiry = 'Required';

    if (!values.date || values.date === null) errors.date = 'Required';

    return errors;
};

function Appointment() {
    const [startDate, setStartDate] = useState(null)
    const [touchedDate, setTouchedDate] = useState(false);

    const [slideIn, setSlideIn] = useState(false);

    const isWeekday = date => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            date: null,
            time: 'none',
            inquiry: '',
            subscribe: true

        },
        validate,
        onSubmit: (values, { resetForm }) => {
            resetForm();
            setStartDate(null)
            setTouchedDate(false)
            setSlideIn(true);
            setTimeout(() => { setSlideIn(false) }, 4000);

            (async () => {
                const rawResponse = await fetch('/send', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values, null, 2)
                });
                const content = await rawResponse.json();
                return content
            })();
        },
    });

    return (
        <React.Fragment>

            <div className="flex justify-center min-h-screen items-center bg-gray-200">

                <div className="container mx-auto">
                    <div className="flex justify-center p-2 md:p-5">

                        <Alert slideIn={slideIn} role="alert">
                            <div className="flex">
                                <div className="py-1"><svg className="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
                                <div>
                                    <p className="font-bold">Thank you for submitting your request</p>
                                    <p className="text-sm">We will review your information and reach out to confirm your appointment</p>
                                </div>
                            </div>
                        </Alert>

                        <BannerContainer style={{ backgroundImage: `url(${banner})`, backgroundPosition: 'center' }} />

                        <FormContainer>
                            <div className='w-24 mx-auto mt-6 md:hidden'>
                                <img src={Logo} alt='Logo' />
                            </div>
                            <h1 className="font-poppins">Book an Appointment</h1>
                            <h2 className="font-poppins">Select a date to visit Rafaello &amp; Co.</h2>

                            <Form onSubmit={formik.handleSubmit}>
                                <div className="md:flex">

                                    <InputField left error={formik.touched.firstName && formik.errors.firstName}>
                                        <label htmlFor="firstName">First Name</label>
                                        <input id="firstName" name="firstName" type="text" placeholder="First Name"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.firstName}
                                        />
                                        {formik.touched.firstName && formik.errors.firstName ? <p className="absolute text-xs italic text-red-500 mb-0">{formik.errors.firstName}</p> : null}
                                    </InputField>

                                    <InputField right error={formik.touched.lastName && formik.errors.lastName}>
                                        <label htmlFor="lastName">Last Name</label>
                                        <input id="lastName" name="lastName" type="text" placeholder="Last Name"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.lastName}
                                        />
                                        {formik.touched.lastName && formik.errors.lastName ? <p className="absolute text-xs italic text-red-500 mb-0">{formik.errors.lastName}</p> : null}
                                    </InputField>

                                </div>
                                <div className="md:flex">
                                    <InputField left className="md:w-3/5" error={formik.touched.email && formik.errors.email}>
                                        <label htmlFor="email">Email</label>
                                        <input id="email" name="email" type="text" placeholder="Email"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}
                                        />
                                        {formik.touched.email && formik.errors.email ? <p className="absolute text-xs italic text-red-500 mb-0">{formik.errors.email}</p> : null}
                                    </InputField>

                                    <InputField right className="md:w-2/5" error={formik.touched.phone && formik.errors.phone}>
                                        <label htmlFor="phone">Phone</label>
                                        <input id="phone" name="phone" type="text" placeholder="Phone"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.phone}
                                        />
                                        {formik.touched.phone && formik.errors.phone ? <p className="absolute text-xs italic text-red-500 mb-0">{formik.errors.phone}</p> : null}
                                    </InputField>
                                </div>

                                <div className="md:flex">

                                    <InputField left className="md:w-1/2" error={(touchedDate && formik.errors.date) || (touchedDate && formik.values.date === null) ? true : false}>
                                        <label htmlFor="date">Date</label>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => {
                                                setStartDate(date)
                                                setTouchedDate(true)
                                                if (date === '') formik.errors.date = "Required"
                                                else delete formik.errors.date
                                                formik.values.date = date
                                            }}
                                            onBlur={(e) => {
                                                setTouchedDate(true)
                                                if (e.currentTarget.value === '') formik.errors.date = "Required"
                                                else delete formik.errors.date
                                            }}
                                            filterDate={isWeekday}
                                            customInput={<input id="date" className="test" name="date" placeholder="Select Date" />}
                                            onMonthChange={() => { window.dispatchEvent(new CustomEvent('resize')) }}
                                            placeholderText={'Select Date'}
                                            minDate={addDays(new Date(), 3)}
                                            maxDate={addDays(new Date(), 60)}
                                        />
                                        {(touchedDate && formik.errors.date) || (touchedDate && formik.values.date === null) ? <p className="absolute text-xs italic text-red-500 mb-0">Required</p> : null}
                                    </InputField>

                                    <InputField right className="md:w-1/2" error={formik.touched.time && formik.errors.time}>
                                        <label htmlFor="time">Time</label>
                                        <select id="time" name="time" className="form-select"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.time}
                                        >
                                            <option value="none" disabled hidden>Find Time</option>
                                            <option disabled>11:00 am</option>
                                            <option disabled>11:30 am</option>
                                            <option value="12">12:00 pm</option>
                                            <option value="12:30 pm">12:30 pm</option>
                                            <option value="1:00 pm">1:00 pm</option>
                                            <option value="1:30 pm">1:30 pm</option>
                                            <option value="2:00 pm">2:00 pm</option>
                                            <option value="2:30 pm">2:30 pm</option>
                                            <option value="3:00 pm">3:00 pm</option>
                                            <option value="3:30 pm">3:30 pm</option>
                                            <option value="4:00 pm">4:00 pm</option>
                                            <option value="4:30 pm">4:30 pm</option>
                                            <option value="5:00 pm">5:00 pm</option>
                                        </select>
                                        {formik.touched.time && formik.errors.time ? <p className="absolute text-xs italic text-red-500 mb-0">{formik.errors.time}</p> : null}
                                    </InputField>

                                </div>

                                <InputField error={formik.touched.inquiry && formik.errors.inquiry}>
                                    <label htmlFor="inquiry">Inquiry about</label>
                                    <textarea id="inquiry" name="inquiry" rows="3" style={{ resize: "none" }}
                                        placeholder="Please describe what are you interested in.."
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.inquiry}
                                    />
                                    {formik.touched.inquiry && formik.errors.inquiry ? <p className="absolute text-xs italic text-red-500 mb-0">{formik.errors.inquiry}</p> : null}
                                </InputField>

                                <Checkbox>
                                    <label className="flex" htmlFor="subscribe">
                                        <input type="checkbox" id="subscribe" name="subscribe" className="form-checkbox" onChange={formik.handleChange} value={formik.values.subscribe} checked={formik.values.subscribe} />
                                        <span className="ml-2 text-sm">Subscribe to our Newsletter. <a rel="nofollow noopener noreferrer" target="_blank" href="https://www.rafaelloandcompany.com/terms-of-use">Terms of Service</a>, <a rel="nofollow noopener noreferrer" target="_blank" href="https://www.rafaelloandcompany.com/privacy-policy">Privacy Policy</a> and default <a rel="nofollow noopener noreferrer" target="_blank" href="https://www.rafaelloandcompany.com/about-rafaello-co">Notification Settings</a> apply</span>
                                    </label>
                                </Checkbox>

                                <ScheduleButton type="submit" onClick={() => { setTouchedDate(true) }}>Schedule</ScheduleButton>
                                <FooterLink><span>This site is protected with reCAPTCHA by Google</span></FooterLink>
                                <FooterLink><span><a rel="nofollow noopener noreferrer" target="_blank" href="https://policies.google.com/privacy">Privacy Policy</a> and <a rel="nofollow noopener noreferrer" target="_blank" href="https://policies.google.com/terms">Terms of Service</a> apply</span>
                                </FooterLink>
                            </Form>
                        </FormContainer>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Appointment