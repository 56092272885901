import styled from "styled-components"
import tw from "tailwind.macro"

const BannerContainer = styled.div.attrs({
  className: "w-full h-auto bg-gray-400 hidden lg:block lg:w-5/12 xl:w-1/3 bg-cover rounded-l-lg",
})``

const FormContainer = styled.div.attrs({
  className: "w-full lg:w-7/12 xl:w-2/3 bg-white md:p-3 rounded-lg lg:rounded-l-none",
})`
& {
  h1 {
    ${tw`pt-4 mx-8 mt-0 md:mt-8 lg:ml-16 xl:ml-48 text-3xl font-bold`}
  }
  h2 {
    ${tw`mx-8 lg:ml-16 xl:ml-48 text-xl font-semibold`}
  }
} 
`

const Form = styled.form.attrs({
  className: "px-8 pt-6 pb-8 mb-4 lg:mx-8 xl:mx-40 bg-white rounded",
})``

const InputField = styled.div.attrs(props => ({
  className: `mb-4 flex-grow ${props.left ? 'md:mr-3 md:mb-0' : props.right ? 'md:ml-3' : ''}`,
}))`
  & {
    label {
      ${tw`block mb-2 text-sm text-gray-900 font-bold`}

    }
    input {
      ${tw`w-full py-2 px-4 bg-gray-200 appearance-none border-2 border-gray-200 rounded text-gray-700 leading-tight focus:outline-none focus:bg-white`}
      ${props => props.error ? tw`border-red-500` : tw`focus:border-gray-400`}
    }
    textarea {
      ${tw`w-full py-2 px-4 bg-gray-200 appearance-none border-2 border-gray-200 rounded text-gray-700 leading-tight focus:outline-none focus:bg-white`}
      ${props => props.error ? tw`border-red-500` : tw`focus:border-gray-400`}
    }
    select {
      ${tw`w-full py-2 px-4 bg-gray-200 appearance-none border-2 border-gray-200 rounded text-gray-500 leading-tight focus:outline-none focus:bg-white focus:text-gray-700`}
      ${props => props.error ? tw`border-red-500` : tw`focus:border-gray-400`}
    }
  }
`

const Checkbox = styled.main.attrs({
  className: "flex",
})`
& {
  input {
    ${tw`mt-1 text-red-600`}
  }
  {
    a {
      ${tw`text-blue-500 underline`}
    }
} 
`

const ScheduleButton = styled.button.attrs(props => ({
  className: `px-16 py-2 mt-6 mb-8 text-white bg-red-600 rounded w-full sm:w-auto hover:bg-red-700 font-semibold font-poppins focus:outline-none focus:shadow-outline`,
}))``

const FooterLink = styled.div.attrs({
  className: "leading-none",
})`
& {
  a {
    ${tw`inline-block p-0 text-xs text-blue-500 align-baseline hover:text-blue-900`}
  }
  span {
    ${tw`inline-block p-0 text-xs text-gray-500 align-baseline`}
  }
}
`

const Alert = styled.div.attrs(props => ({
  className: `fixed w-11/12 lg:w-4/5 xl:w-2/3 top-0 my-2 md:my-5 bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md z-10
  ${props.left ? 'md:mr-3 md:mb-0' : props.right ? 'md:ml-3' : ''}`,
}))`
  transform: translateY(-150px);
  transition: transform 250ms;
  ${props => props.slideIn ? 'transition-timing-function: ease-out; transform: translateY(0);' : 'transition-timing-function: ease-in'}
  
`

export { BannerContainer, FormContainer, Form, InputField, Checkbox, ScheduleButton, FooterLink, Alert }